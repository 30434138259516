<!-- Ce composant gère la barre d'outils des cartes -->
<template>
  <v-app-bar elevation="4" class="ml-15 mt-4 mr-15 app-bar-style" style="z-index: 3;" color="white" rounded absolute max-width="100%">
    <v-spacer/>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-toolbar-title v-bind="attrs" v-on="on" class="pb-0 mb-0" style="text-align: center; font-size: 1.1vw;" @click="displaySearch">{{ cityName }}</v-toolbar-title>
      </template>
      <span>{{ cityName }}</span>
    </v-tooltip>
    <v-spacer/>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon @click="goToStat()" v-bind="attrs" v-on="on">
          <v-icon>mdi-arrow-down</v-icon>
        </v-btn>
      </template>
      <span>Accéder aux statistiques</span>
    </v-tooltip>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon @click="displaySearch()" :color="searchActivated ? '#0075ce' : ''" v-bind="attrs" v-on="on">
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
      </template>
      <span>Rechercher une entité</span>
    </v-tooltip>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon @click="toggleInfos()" :color="infos ? '#0075ce' : ''" v-bind="attrs" v-on="on">
          <v-icon>mdi-map-marker-question</v-icon>
        </v-btn>
      </template>
      <span>Informations sur la carte</span>
    </v-tooltip>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn @click="toggleOption(3)" :color="optionActivated ? '#0075ce' : ''" icon v-bind="attrs" v-on="on">
          <v-icon>mdi-cog</v-icon>
        </v-btn>
      </template>
      <span>Options de carte</span>
    </v-tooltip>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn @click="rezoom()" icon v-bind="attrs" v-on="on">
          <v-icon>mdi-arrow-expand-all</v-icon>
        </v-btn>
      </template>
      <span>Recentrer la carte</span>
    </v-tooltip>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn @click="reloadRegion()" icon v-bind="attrs" v-on="on">
          <v-icon>mdi-arrow-collapse</v-icon>
        </v-btn>
      </template>
      <span>Retourner à l'échelle de la Région</span>
    </v-tooltip>
    <v-divider vertical class="ml-1 mr-1"/>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn @click="linkViews()" icon v-bind="attrs" v-on="on" :color="viewLinked ? '#0075ce' : ''">
          <v-icon v-if="!viewLinked">mdi-lock-open</v-icon>
          <v-icon v-else>mdi-lock</v-icon>
        </v-btn>
      </template>
      <span>Verrouiller les vues cartographiques sur celle-ci</span>
    </v-tooltip>
  </v-app-bar>
</template>

<script>
export default {
  name: "CompareToolbar",
  props: {
    cityArea: {
      type: Number
    },
    cityHistorized: {
      type: Object
    },
    cityName: {
      type: String,
      required: false
    },
    map: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      infos: false,
      optionActivated: false,
      searchActivated: false,
      viewLinked: false,
    }
  },
  methods: {
    // Balance l'évènement de recherche
    displaySearch() {
      this.searchActivated = !this.searchActivated
      this.$emit('toggleSearch')
    },
    // Déroule la page pour arriver aux statistiques
    goToStat() {
      this.$emit('goToStat')
    },
    linkViews() {
      this.viewLinked = !this.viewLinked
      this.$emit('linkViews')
    },
    reloadRegion() {
      this.$emit('reloadRegion')
    },
    // Permet à l'utilisateur de revenir sur le territoire observé
    rezoom() {
      this.$emit("rezoom")
    },
    // Permet d'activer ou non le click sur la map pour afficher des informations de features
    toggleInfos() {
      this.infos = !this.infos
      this.$emit('toggleInfos', this.infos)
    },
    // Permet d'activer ou non l'affichage du drawer
    toggleOption(option) {
      var data = { "option": option, "map": this.map }
      this.optionActivated = !this.optionActivated
      this.$emit('displayOption', data)
    },
  }
}
</script>

<style>
.app-bar-style {
  opacity: 0.9;
  z-index: 3;
}
</style>