<!-- Ce composant affiche deux cartes côte à côte et affiche des statistiques pour deux villes différentes -->
<template>
  <v-container style="max-width: inherit">
    <v-row id="map-reference" align="start" justify="center">
      <!-- 1ere map (gauche) -->
      <v-col style="position: relative">
        <CompareToolbar :map="1" @linkViews="linkView(1)" @goToStat="goToStat" @rezoom="rezoom(1)" @reloadRegion="reloadRegion(1)" :cityArea="cityArea" :cityName="cityName" @displayOption="toggleOption" @toggleSearch="toggleSearch(1)" @toggleInfos="toggleInfos($event, 1)"/>
        <div class="compare-map" id="compare-div-1">
          <v-expand-transition>
            <CompareSearch v-show="displaySearch1" @citySelected="elementSearched($event, 1)"/>
          </v-expand-transition>
          <!-- Container affichant les informations sur l'élément cliqué -->
          <div id="popup" class="ol-popup" transition="scroll-y-transition">
            <v-container>
              <v-card flat>
                <v-row>
                  <v-col class="pa-0 ma-0 mt-5 ml-2">
                    <v-btn icon class="ol-popup-closer" @click="deleteFeature(1)">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <div id="popup-content">
                      <h3 class="mb-2">OCS GE2 - {{ overlayContent.year }}</h3>
                      <v-divider/>
                      <div v-if="!overlayContent.errorBool">
                        <p class="ma-0"><b>{{ overlayContent.code1 }}</b> : {{ overlayContent.niv1 }}</p>
                        <p class="ma-0 ml-2" v-if="this.levelSelected >= 2"><b>{{ overlayContent.code2 }}</b> : {{ overlayContent.niv2 }}</p>
                        <p class="ma-0 ml-4" v-if="this.levelSelected >= 3"><b>{{ overlayContent.code3 }}</b> : {{ overlayContent.niv3 }}</p>
                        <p class="ma-0 ml-6" v-if="this.levelSelected >= 4"><b>{{ overlayContent.code4 }}</b> : {{ overlayContent.niv4 }}</p>
                        <p class="ma-0" v-if="this.levelSelected >= 5"><b>{{ overlayContent.code5 }}</b> : {{ overlayContent.niv5 }}</p>
                      </div>
                      <div v-else>
                        <p class="ma-0">{{ overlayContent.error }}</p>
                      </div>
                      <v-divider/>
                      <p class="ma-0 mt-2"><b>Surface</b> : {{ parseFloat(overlayContent.area).toFixed(2) }} ha</p>
                    </div>
                  </v-col>
                </v-row>
              </v-card>
            </v-container>
          </div>
          <v-slide-y-reverse-transition>
            <CompareOptions v-show="displayOptionDrawer1" @newLevel="changeLevel($event, 1)" @dateChanged="changeDate" @updateOpacity="setOpacity" :optionDisplay="optionDisplay" @changeBasemap="setBasemap($event)" @changeMapStyle="updateMapLayers"/>
          </v-slide-y-reverse-transition>
        </div>
        <v-btn id="analyse-button" color="#4473c5" style="text-transform: inherit" elevation="0" dark @click="dialog = true">
          Analyses avancées
        </v-btn>
        <!-- <v-btn class="ml-1" color="#4473c5" elevation="0" style="text-transform: inherit" dark href="https://www.datagrandest.fr/portail/fr/projets/occupation-du-sol" target="_blank">
          Portraits de territoire et données OCS GE 2
        </v-btn>
        <v-btn color="#4473c5" elevation="0" style="text-transform: inherit" dark download href="OCSGE2_description_indicateurs_plateforme_version2_avril2022.pdf" class="ml-1">Méthodes de calcul</v-btn> -->
        <div class="mt-2">
          <!-- Surface de l'entité -->
          <div class="details-subtitle">
            <span style="color: black">Surface totale du territoire : </span> {{ formatNumber(Math.round(cityArea / 1000000)) }} km2
          </div>
          <!-- Population de l'entité -->
          <div class="details-subtitle" v-if="cityPopulation !== null">
            <span style="color: black">Population : </span> {{ formatNumber(cityPopulation.pmun) }} habitants en {{ cityPopulation.millesime_rp }}
          </div>
        </div>
        <!-- Grille de statistiques -->
        <MainStat :items="items" :year1="year11" :year2="year12" v-if="this.displayValue === 0"/>
        <!-- différents affichages pour les statistiques disponibles -->
        <Step1 v-if="displayValue === 1" :city="currentCity" :cityType="cityType" :millesime1="year11" :millesime2="year12"></Step1>
        <Step2 v-if="displayValue === 2" :city="currentCity" :cityType="cityType" :millesime1="year11" :millesime2="year12"></Step2>
        <Step3 v-if="displayValue === 3" :city="currentCity" :cityType="cityType" :millesime1="year11" :millesime2="year12"></Step3>
        <Step4 v-if="displayValue === 4" :city="currentCity" :cityType="cityType" :millesime1="year11" :millesime2="year12"></Step4>
        <Step5 v-if="displayValue === 5" :city="currentCity" :cityType="cityType" :millesime1="year11" :millesime2="year12"></Step5>
        <Step6 v-if="displayValue === 6" :city="currentCity" :cityType="cityType" :millesime1="year11" :millesime2="year12"></Step6>
      </v-col>

      <!-- 2e map (droite) -->
      <v-col style="position: relative">
        <CompareToolbar :map="2" :cityName="cityName2" :cityArea="cityArea2" @linkViews="linkView(2)" @reloadRegion="reloadRegion(2)" @rezoom="rezoom(2)" @goToStat="goToStat" @displayOption="toggleOption" @toggleSearch="toggleSearch(2)" @toggleInfos="toggleInfos($event, 2)"/>
        <div class="compare-map" id="compare-div-2">
          <v-expand-transition>
            <CompareSearch v-show="displaySearch2" @citySelected="elementSearched($event, 2)"/>
          </v-expand-transition>
          <!-- Container affichant les informations sur l'élément cliqué -->
          <div id="popup2" class="ol-popup" transition="scroll-y-transition">
            <v-container>
              <v-card flat>
                <v-row>
                  <v-col class="pa-0 ma-0 mt-5 ml-2">
                    <v-btn icon class="ol-popup-closer" @click="deleteFeature(2)">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <div id="popup-content">
                      <h3 class="mb-2">OCS GE2 - {{ overlayContent2.year }}</h3>
                      <v-divider/>
                      <div v-if="!overlayContent2.errorBool">
                        <p class="ma-0"><b>{{ overlayContent2.code1 }}</b> : {{ overlayContent2.niv1 }}</p>
                        <p class="ma-0 ml-2" v-if="this.levelSelected2 >= 2"><b>{{ overlayContent2.code2 }}</b> : {{ overlayContent2.niv2 }}</p>
                        <p class="ma-0 ml-4" v-if="this.levelSelected2 >= 3"><b>{{ overlayContent2.code3 }}</b> : {{ overlayContent2.niv3 }}</p>
                        <p class="ma-0 ml-6" v-if="this.levelSelected2 >= 4"><b>{{ overlayContent2.code4 }}</b> : {{ overlayContent2.niv4 }}</p>
                        <p class="ma-0" v-if="this.levelSelected2 >= 5"><b>{{ overlayContent2.code5 }}</b> : {{ overlayContent2.niv5 }}</p>
                      </div>
                      <div v-else>
                        <p class="ma-0">{{ overlayContent2.error }}</p>
                      </div>
                      <v-divider/>
                      <p class="ma-0 mt-2"><b>Surface</b> : {{ parseFloat(overlayContent2.area).toFixed(2) }} ha</p>
                    </div>
                  </v-col>
                </v-row>
              </v-card>
            </v-container>
          </div>
          <v-slide-y-reverse-transition>
            <CompareOptions v-show="displayOptionDrawer2" @updateOpacity="setOpacity2" @dateChanged="changeDate2" @newLevel="changeLevel($event, 2)" :optionDisplay="optionDisplay" @changeBasemap="setBasemap2($event)" @changeMapStyle="updateMapLayers2"/>
          </v-slide-y-reverse-transition>
        </div>
        <v-btn id="analyse-button" color="#4473c5" style="text-transform: inherit" elevation="0" dark @click="dialog = true">
          Analyses avancées
        </v-btn>
        <div class="mt-2">
          <!-- Surface de l'entité -->
          <div class="details-subtitle">
            <span style="color: black">Surface totale du territoire : </span> {{ formatNumber(Math.round(cityArea2 / 1000000)) }} km2
          </div>
          <!-- Population de l'entité -->
          <div class="details-subtitle" v-if="cityPopulation !== null">
            <span style="color: black">Population : </span> {{ formatNumber(cityPopulation2.pmun) }} habitants en {{ cityPopulation2.millesime_rp }}
          </div>
        </div>
        <MainStat :items="items2" :year1="year21" :year2="year22" v-if="this.displayValue === 0"/>
        <!-- différents affichages pour les statistiques disponibles -->
        <Step1 v-if="displayValue === 1" :mapId="2" :city="currentCity2" :cityType="cityType2" :millesime1="year21" :millesime2="year22"></Step1>
        <Step2 v-if="displayValue === 2" :mapId="2" :city="currentCity2" :cityType="cityType2" :millesime1="year21" :millesime2="year22"></Step2>
        <Step3 v-if="displayValue === 3" :mapId="2" :city="currentCity2" :cityType="cityType2" :millesime1="year21" :millesime2="year22"></Step3>
        <Step4 v-if="displayValue === 4" :mapId="2" :city="currentCity2" :cityType="cityType2" :millesime1="year21" :millesime2="year22"></Step4>
        <Step5 v-if="displayValue === 5" :mapId="2" :city="currentCity2" :cityType="cityType2" :millesime1="year21" :millesime2="year22"></Step5>
        <Step6 v-if="displayValue === 6" :mapId="2" :city="currentCity2" :cityType="cityType2" :millesime1="year21" :millesime2="year22"></Step6>
      </v-col>
    </v-row>
    <UpBtn :custom="'#map-reference'"/>
    <!-- Dialog that is opened when the "Analyse avancée" button is clicked. -->
    <AnalyseDialog
      :dialog="dialog"
      @updateDialog="setDisplay"
    ></AnalyseDialog>

    <div>
      <v-btn class="ma-1" color="#4473c5" dark elevation="0" style="text-transform: inherit" href="https://www.datagrandest.fr/portail/fr/projets/occupation-du-sol#pdf" target="__blank">
        Portrait de territoire
      </v-btn>
      <v-btn download href="OCSGE2_description_indicateurs_plateforme_version2_avril2022.pdf" class="ma-1" color="#4473c5" dark elevation="0" style="text-transform: inherit">Méthodes de calcul</v-btn>
    </div>

    <!-- Crédits -->
    <v-subheader class="mt-3">
      © OpenStreetMap contributors.
    </v-subheader>
    <v-subheader>
      <div>Sources : <a target="__blank" href="https://www.datagrandest.fr/geonetwork/srv/fre/catalog.search#/search?resultType=details&sortBy=relevance&fast=index&_content_type=json&from=1&to=20&any=OCSGE2">Catalogue DataGrandEst</a>, <a href="https://www.datagrandest.fr/geonetwork/srv/fre/catalog.search#/metadata/FR-200052264-Commune_Grand_Est" target="__blank">Communes et délimitations administratives du Grand Est</a>.</div>
    </v-subheader>
  </v-container>
</template>

<script>
// Importons nos composants customs
import CompareToolbar from "@/components/Compare/CompareToolbar"
import CompareOptions from "@/components/Compare/CompareOptions"
import CompareSearch from "@/components/Compare/CompareSearch"
import AnalyseDialog from "@/components/Explore/AnalyseDialog"
import Step1 from "@/components/Explore/steppers/Step1";
import Step2 from "@/components/Explore/steppers/Step2";
import Step3 from "@/components/Explore/steppers/Step3";
import Step4 from "@/components/Explore/steppers/Step4";
import Step5 from "@/components/Explore/steppers/Step5";
import Step6 from "@/components/Explore/steppers/Step6";
import UpBtn from "@/components/UpBtn";
import MainStat from "@/components/Explore/MainStat";
import { transformExtent } from "ol/proj";

// Importons les composants nécessaire au fonctionnement d'openlayers
import "ol/ol.css";
import Map from "ol/Map";
import View from "ol/View";
import TileLayer from "ol/layer/Tile";
import { fromLonLat, transform, toLonLat } from "ol/proj";
import XYZ from "ol/source/XYZ";
import { ScaleLine, defaults as defaultControls } from "ol/control";
import WMTS, { optionsFromCapabilities } from "ol/source/WMTS";
import WMTSCapabilities from "ol/format/WMTSCapabilities";
import Overlay from 'ol/Overlay';
import proj4 from "proj4";
import { register } from "ol/proj/proj4";
// Importons les outils nous permettant de dessiner les features
import GeoJSON from 'ol/format/GeoJSON';
import {Vector as VectorSource} from 'ol/source';
import {Vector as VectorLayer} from 'ol/layer';
// Importons de quoi styliser la feature à afficher
import {Fill, Stroke, Style} from 'ol/style';
// Importons axios pour réaliser nos requêtes
import axios from "axios";

export default {
  name: "Compare",
  components: {
    AnalyseDialog,
    CompareToolbar,
    CompareOptions,
    CompareSearch,
    MainStat,
    Step1,
    Step2,
    Step3,
    Step4,
    Step5,
    Step6,
    UpBtn
  },
  data: function () {
    return {
      basemap: undefined,
      basemapLabel: undefined,
      basemapLabel2: undefined,
      cityArea: 57705 * 1000000,
      cityArea2: 57705 * 1000000,
      cityName: "Région Grand Est",
      cityName2: "Région Grand Est",
      cityHistorized1: {
        id: null,
        niveau: null
      },
      cityHistorized2: {
        id: null,
        niveau: null
      },
      cityPopulation: {
        pmun: 5556219,
        millesime_rp: 2019
      },
      cityPopulation2: {
        pmun: 5556219,
        millesime_rp: 2019
      },
      cityType: "region",
      cityType2: "region",
      currentCity: undefined,
      currentCityStat: undefined,
      currentCity2: undefined,
      currentCityStat2: undefined,
      currentRegionStat: null,
      currentRegionStat2: null,
      dataToLoad: "2019",
      dialog: false,
      displayOptionDrawer1: false,
      displayOptionDrawer2: false,
      displaySearch1: false,
      displaySearch2: false,
      displayValue: 0,
      dataToLoad2: "2019",
      items: null,
      items2: null,
      levelSelected: 1,
      levelSelected2: 1,
      limitesCommunesLayer: undefined,
      limitesEpciLayer: undefined,
      limitesScotLayer: undefined,
      limitesPnrLayer: undefined,
      limitesDepartementLayer: undefined,
      limitesCommunesLayer2: undefined,
      limitesEpciLayer2: undefined,
      limitesScotLayer2: undefined,
      limitesPnrLayer2: undefined,
      limitesDepartementLayer2: undefined,
      map: undefined,
      map2: undefined,
      mapLinked: false,
      optionDisplay: undefined,
      ourLayer: undefined,
      ourLayer2: undefined,
      // Ajoute un overlay sur la carte
      overlay: undefined,
      overlay2: undefined,
      // Gère le contenu de l'overlay
      overlayContent: {},
      overlayContent2: {},
      // Le vector nous permettant d'afficher les features retournées par handleOverlay
      vectorSource: undefined,
      vectorLayer: undefined,
      // Le vector nous permettant d'afficher les features retournées par handleOverlay
      vectorSource2: undefined,
      vectorLayer2: undefined,
      view: undefined,
      view2: undefined,
      year11 : "2018-2019",
      year12: "2007-2010",
      year21: "2018-2019",
      year22: "2007-2010",
      zoom: undefined,
      zoom2: undefined,
    }
  },
  computed: {
    // Renvoi dynamiquement la donnée à afficher
    urlLayer() {
      let result
      result =
        process.env.VUE_APP_MAPSERVER_URL +
        "data_tileset_" +
        this.dataToLoad + "_" + this.levelSelected +
        "@kermap/{z}/{x}/{-y}.png";
      return result;
    },
    // Renvoi dynamiquement la donnée à afficher
    urlLayer2() {
      let result
      result =
        process.env.VUE_APP_MAPSERVER_URL +
        "data_tileset_" +
        this.dataToLoad2 + "_" + this.levelSelected2 +
        "@kermap/{z}/{x}/{-y}.png";
      return result;
    },
  },
  mounted () {
    // Initialise les cartes
    this.createMap()
    // Récupère les statistiques de la région
    this.initRegionStat(1)
    this.initRegionStat(2)
    // Initialise les éléments pour l'overlay
    const container = document.getElementById('popup');
    const container2 = document.getElementById('popup2');
    this.overlayContent = {
      "year": "",
      "code1": "",
      "niv1": "",
      "code2": "",
      "niv2": "",
      "code3": "",
      "niv3": "",
      "code4": "",
      "niv4": "",
      "code5": "",
      "niv5": "",
      "area": "",
      "errorBool": false,
      "error": "Malheureusement, cette entité n'est pas référencée"
    }
    this.overlayContent2 = {
      "year": "",
      "code1": "",
      "niv1": "",
      "code2": "",
      "niv2": "",
      "code3": "",
      "niv3": "",
      "code4": "",
      "niv4": "",
      "code5": "",
      "niv5": "",
      "area": "",
      "errorBool": false,
      "error": "Malheureusement, cette entité n'est pas référencée"
    }
    this.overlay = new Overlay({
      element: container,
      autoPan: true,
      autoPanAnimation: {
        duration: 250
      }
    })
    this.overlay2 = new Overlay({
      element: container2,
      autoPan: true,
      autoPanAnimation: {
        duration: 250
      }
    })
  },
  methods: {
    // Change les dates des millésimes
    changeDate(val) {
      this.dataToLoad = val;
    },
    changeDate2(val) {
      this.dataToLoad2 = val;
    },
    // Change le niveau de nomenclature des cartes
    changeLevel(newLevel, mapId) {
      if (mapId === 1) {
        this.levelSelected = newLevel 
      } else {
        this.levelSelected2 = newLevel
      }
    },
    // Zoom sur la communes sélectionnée et affiche ses statistiques
    // La garde également en mémoire pour permettre à l'utilisateur de revenir rapidement dessus
    citySearched(city, mapId) {
      var data = { objectid: city.objectid };
      var data_stat = { code_admin: city.insee_com };
      var self = this;
      axios
        .post(process.env.VUE_APP_API_URL + "communesgeom", data)
        .then(function (result) {
          proj4.defs(
            "EPSG:2154",
            "+proj=lcc +lat_1=49 +lat_2=44 +lat_0=46.5 +lon_0=3 +x_0=700000 +y_0=6600000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs"
          );
          register(proj4);
          if (mapId === 1) {
            self.view.setCenter(
              transform(
                [result.data.coordinates[0], result.data.coordinates[1]],
                "EPSG:2154",
                "EPSG:3857"
              )
            );
            self.view.setZoom(13);
          } else {
            self.view2.setCenter(
              transform(
                [result.data.coordinates[0], result.data.coordinates[1]],
                "EPSG:2154",
                "EPSG:3857"
              )
            );
            self.view2.setZoom(13);
          }
        });
      axios
        .post(
          process.env.VUE_APP_API_URL + "indicateurviewer/particularcommstat",
          data_stat
        )
        .then(function (result) {
          if (mapId === 1) {
            self.currentCityStat = result.data;
            self.cityType = "commune"
          } else {
            self.currentCityStat2 = result.data;
            self.cityType2 = "commune"
          }
        });
      if (mapId === 1) {
        this.currentCity = city;
        this.cityName = city.nom_com_min
      } else {
        this.currentCity2 = city;
        this.cityName2 = city.nom_com_min
      }
      this.cityHistorized = city;
      this.citySelected = true;
      if (mapId === 1) {
        this.updateMapLayers("communes");
      } else {
        this.updateMapLayers2("communes")
      }
    },
    createMap() {
      // Ici, nous initialisons la carte avec la basemap OSM
      // Nous pouvons modifier l'appel à la fonction ici pour initialiser la carte avec le flux orthophotographique
      this.classicBasemap();
    },
    // Fonction permettant d'initialiser la carte avec la basemap OSM classique (utilisé)
    classicBasemap() {
      var self = this;
      var parser = new WMTSCapabilities();
      axios
        .get(
          "https://osm.datagrandest.fr/mapcache/wmts?service=WMTS&request=GetCapabilities"
        )
        .then(function (response) {
          var result = parser.read(response.data);
          var options = optionsFromCapabilities(result, {
            layer: "pure",
            matrixSet: "EPSG:3857",
          });
          self.basemap = new TileLayer({
            source: new WMTS(options),
          });
          self.basemap2 = new TileLayer({
            source: new WMTS(options),
          });
          self.createMapLabel();
        });
    },
    // Initialise les labels à afficher (principalement les noms de communes)
    createMapLabel() {
      var self = this;
      var parser = new WMTSCapabilities();
      axios
        .get(
          "https://osm.datagrandest.fr/mapcache/wmts?service=WMTS&request=GetCapabilities"
        )
        .then(function (response) {
          var result = parser.read(response.data);
          var options = optionsFromCapabilities(result, {
            layer: "overlay",
            matrixSet: "EPSG:3857",
          });
          self.basemapLabel = new TileLayer({
            source: new WMTS(options),
          });
          self.basemapLabel2 = new TileLayer({
            source: new WMTS(options),
          });
          self.initMapObject();
        });
    },
    // Retire les overlays (info-bulle)
    deleteFeature (mapId) {
      if (mapId === 1) {
        this.overlay.setPosition(undefined);
      } else {
        this.overlay2.setPosition(undefined)
      }
    },
    // A la recherche d'un élément, nous regardons le niveau de l'entité sélectionnée puis nous
    // appelons la fonction correspondante
    elementSearched (element, mapId) {
      var data
      if (mapId === 1) {
        this.cityHistorized1.id = element.id
        this.cityHistorized1.niveau = element.niveau
        this.cityHistorized1.nom = element.nom
        data = { 'id': element.id }
        axios.post(process.env.VUE_APP_API_URL + "getpopulationdata", data)
        .then((result) => {
          this.cityPopulation = result.data
        })
      } else {
        this.cityHistorized2.id = element.id
        this.cityHistorized2.niveau = element.niveau
        this.cityHistorized2.nom = element.nom
        data = { 'id': element.id }
        axios.post(process.env.VUE_APP_API_URL + "getpopulationdata", data)
        .then((result) => {
          this.cityPopulation2 = result.data
        })
      }
      if (element.niveau === 'commune') {
        this.particularCommuneSearch(element, mapId)
      } else if (element.niveau === 'departement') {
        this.particularDepartementSearch(element, mapId)
      } else if (element.niveau === 'epci') {
        this.particularEpciSearch(element, mapId)
      } else if (element.niveau === 'pnr') {
        this.particularPnrSearch(element, mapId)
      } else if (element.niveau === 'scot') {
        this.particularScotSearch(element, mapId)
      }
    },
    formatNumber (value) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },
    // Cette fonction gère l'affichage et la position de l'overlay au click sur la carte
    // (si la fonctionnalité est activée)
    handleOverlay(event) {
      const coordinates = event.coordinate
      const highlightStyle = new Style({
        fill: new Fill({
          color: 'rgba(255,255,255,0.7)',
        }),
        stroke: new Stroke({
          color: '#3399CC',
          width: 5,
        }),
      });
      var data = { "coord": toLonLat(coordinates), "date": this.dataToLoad }
      axios.post(process.env.VUE_APP_API_URL + 'ocs/get_feature', data)
      .then((response) => {
        this.vectorSource.clear()
        if (response.data === 'error') {
          this.overlay.setPosition(coordinates);
          this.overlayContent.errorBool = true
          this.overlayContent.area = ""
          this.overlayContent.year = ""
        } else {
          this.overlayContent.errorBool = false
          this.overlayContent.year = response.data.millesime
          this.overlayContent.code1 = response.data.cod_n1
          this.overlayContent.niv1 = response.data.lib_n1
          this.overlayContent.code2 = response.data.cod_n2
          this.overlayContent.niv2 = response.data.lib_n2
          this.overlayContent.code3 = response.data.cod_n3
          this.overlayContent.niv3 = response.data.lib_n3
          this.overlayContent.code4 = response.data.cod_n4
          this.overlayContent.niv4 = response.data.lib_n4
          this.overlayContent.code5 = response.data.cod_n5
          this.overlayContent.niv5 = response.data.lib_n5
          this.overlayContent.area = response.data.surf_ha
          this.vectorSource.addFeature(new GeoJSON().readFeature(response.data.shape))
          this.vectorSource.getFeatures().forEach((feature) => {
            feature.setStyle(highlightStyle)
          })
          this.overlay.setPosition(coordinates);
        }
      })
    },
    goToStat () {
      // Déroule la page pour arriver aux statistiques
      this.$vuetify.goTo('#analyse-button')
    },
    handleOverlay2(event) {
      const coordinates = event.coordinate
      const highlightStyle = new Style({
        fill: new Fill({
          color: 'rgba(255,255,255,0.7)',
        }),
        stroke: new Stroke({
          color: '#3399CC',
          width: 5,
        }),
      });
      var data = { "coord": toLonLat(coordinates), "date": this.dataToLoad2 }
      axios.post(process.env.VUE_APP_API_URL + 'ocs/get_feature', data)
      .then((response) => {
        this.vectorSource2.clear()
        if (response.data === 'error') {
          this.overlayContent2.errorBool = true
          this.overlayContent2.area = ""
          this.overlayContent2.year = ""
        } else {
          this.overlayContent2.errorBool = false
          this.overlayContent2.year = response.data.millesime
          this.overlayContent2.code1 = response.data.cod_n1
          this.overlayContent2.niv1 = response.data.lib_n1
          this.overlayContent2.code2 = response.data.cod_n2
          this.overlayContent2.niv2 = response.data.lib_n2
          this.overlayContent2.code3 = response.data.cod_n3
          this.overlayContent2.niv3 = response.data.lib_n3
          this.overlayContent2.code4 = response.data.cod_n4
          this.overlayContent2.niv4 = response.data.lib_n4
          this.overlayContent2.code5 = response.data.cod_n5
          this.overlayContent2.niv5 = response.data.lib_n5
          this.overlayContent2.area = response.data.surf_ha
          this.vectorSource2.addFeature(new GeoJSON().readFeature(response.data.shape))
          this.vectorSource2.getFeatures().forEach((feature) => {
            feature.setStyle(highlightStyle)
          })
          this.overlay2.setPosition(coordinates);
        }
      })
    },
    // Initialise les objets présents sur la map
    initMapObject() {
      var self = this;
      // Définie une échelle pour la carte
      var scalelineControl = new ScaleLine();
      var scalelineControl2 = new ScaleLine();
      var center = fromLonLat([6.87, 48.76]);
      var center2 = fromLonLat([6.87, 48.76]);
      // Défini le zoom de la carte
      self.zoom = 8.5;
      self.zoom2 = 8.5;
      // Défini la vue de la carte
      self.view = new View({
        zoom: self.zoom,
        center: center,
        maxZoom: 18,
        minZoom: 8,
      });
      self.view2 = new View({
        zoom: self.zoom2,
        center: center2,
        maxZoom: 18,
        minZoom: 8,
      });
      // On ajoute notre layer à la carte
      self.ourLayer = new TileLayer({
        source: new XYZ({
          url: self.urlLayer,
        }),
      });

      // On ajoute notre layer à la carte
      self.ourLayer2 = new TileLayer({
        source: new XYZ({
          url: self.urlLayer2,
        }),
      });

      // Nous définissons les layers des différentes limites administratives
      self.limitesCommunesLayer = new TileLayer({
        source: new XYZ({
          url:
            process.env.VUE_APP_MAPSERVER_URL +
            "limites_communes_tileset@kermap/{z}/{x}/{-y}.png",
        }),
      });

      self.limitesEpciLayer = new TileLayer({
        source: new XYZ({
          url:
            process.env.VUE_APP_MAPSERVER_URL +
            "limites_epci_tileset@kermap/{z}/{x}/{-y}.png",
        }),
      });

      self.limitesScotLayer = new TileLayer({
        source: new XYZ({
          url:
            process.env.VUE_APP_MAPSERVER_URL +
            "limites_scot_tileset@kermap/{z}/{x}/{-y}.png",
        }),
      });

      self.limitesPnrLayer = new TileLayer({
        source: new XYZ({
          url:
            process.env.VUE_APP_MAPSERVER_URL +
            "limites_pnr_tileset@kermap/{z}/{x}/{-y}.png",
        }),
      });

      self.limitesDepartementLayer = new TileLayer({
        source: new XYZ({
          url:
            process.env.VUE_APP_MAPSERVER_URL +
            "limites_departements_tileset@kermap/{z}/{x}/{-y}.png",
        }),
      });

      // Nous définissons les layers des différentes limites administratives
      self.limitesCommunesLayer2 = new TileLayer({
        source: new XYZ({
          url:
            process.env.VUE_APP_MAPSERVER_URL +
            "limites_communes_tileset@kermap/{z}/{x}/{-y}.png",
        }),
      });

      self.limitesEpciLayer2 = new TileLayer({
        source: new XYZ({
          url:
            process.env.VUE_APP_MAPSERVER_URL +
            "limites_epci_tileset@kermap/{z}/{x}/{-y}.png",
        }),
      });

      self.limitesScotLayer2 = new TileLayer({
        source: new XYZ({
          url:
            process.env.VUE_APP_MAPSERVER_URL +
            "limites_scot_tileset@kermap/{z}/{x}/{-y}.png",
        }),
      });

      self.limitesPnrLayer2 = new TileLayer({
        source: new XYZ({
          url:
            process.env.VUE_APP_MAPSERVER_URL +
            "limites_pnr_tileset@kermap/{z}/{x}/{-y}.png",
        }),
      });

      self.limitesDepartementLayer2 = new TileLayer({
        source: new XYZ({
          url:
            process.env.VUE_APP_MAPSERVER_URL +
            "limites_departements_tileset@kermap/{z}/{x}/{-y}.png",
        }),
      });

      // Définissons le layer vectorielle visant à accueillir les features
      // retournée par la fonction handleOverlay()
      self.vectorSource = new VectorSource({
        format: new GeoJSON({dataProjection: 'EPSG:4328'})
      })
      self.vectorLayer = new VectorLayer({
        source: self.vectorSource,
        style: this.styleFunction
      })

      // Définissons le layer vectorielle visant à accueillir les features
      // retournée par la fonction handleOverlay()
      self.vectorSource2 = new VectorSource({
        format: new GeoJSON({dataProjection: 'EPSG:4328'})
      })
      self.vectorLayer2 = new VectorLayer({
        source: self.vectorSource2,
        style: this.styleFunction
      })

      // Initialisation de la carte
      self.map = new Map({
        controls: defaultControls().extend([scalelineControl]),
        target: "compare-div-1",
        view: self.view,
        overlays: [self.overlay],
        layers: [
          self.basemap,
          self.ourLayer,
          self.limitesDepartementLayer,
          self.basemapLabel,
          self.vectorLayer
        ],
      });

      // Initialisation de la carte
      self.map2 = new Map({
        controls: defaultControls().extend([scalelineControl2]),
        target: "compare-div-2",
        view: self.view2,
        overlays: [self.overlay2],
        layers: [
          self.basemap2,
          self.ourLayer2,
          self.limitesDepartementLayer2,
          self.basemapLabel2,
          self.vectorLayer2
        ],
      });

      // On ajoute un listener d'évènement sur le déplacement de la carte pour garder notre valeur de zoom à jour
      self.map.on("moveend", function () {
        self.zoom = self.map.getView().getZoom();
      });

      // On ajoute un listener d'évènement sur le déplacement de la carte pour garder notre valeur de zoom à jour
      self.map2.on("moveend", function () {
        self.zoom2 = self.map2.getView().getZoom();
      });
    },
    // Lance l'initialisation du composant avec les statistiques de la région si aucun élément n'est sélectionné
    initRegionStat (mapId) {
      var self = this
      var data_stat = {code_admin: "44"}
      axios.post(process.env.VUE_APP_API_URL + "indicateurviewer/particularregionstat", data_stat)
      .then(function (result) {
        if (mapId === 1) {
          self.currentRegionStat = result.data;
        } else if (mapId === 2) {
          self.currentRegionStat2 = result.data;
        }
      });
    },
    linkView(mapId) {
      if (this.mapLinked) {
        this.mapLinked = false
        if (mapId === 1) {
          this.view2.setCenter(this.view.getCenter())
          this.view2.setZoom(this.view.getZoom())
          this.map2.setView(this.view2)
        } else {
          this.view.setCenter(this.view2.getCenter())
          this.view.setZoom(this.view2.getZoom())
          this.map.setView(this.view)
        }
      } else {
        this.mapLinked = true
        if (mapId === 1) {
          this.map2.setView(this.view)
        } else {
          this.map.setView(this.view2)
        }
      }
    },
    // Fonction de recherche d'une commune
    particularCommuneSearch (element, mapId) {
      var data = { insee_com: element.id }
      var data_stat = { code_admin: element.id };
      var self = this;
      axios
        .post(process.env.VUE_APP_API_URL + "inseecommunesgeom", data)
        .then(function (result) {
          proj4.defs(
            "EPSG:2154",
            "+proj=lcc +lat_1=49 +lat_2=44 +lat_0=46.5 +lon_0=3 +x_0=700000 +y_0=6600000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs"
          );
          register(proj4);
          if (mapId === 1) {
            self.view.setCenter(
              transform(
                [result.data.coordinates[0], result.data.coordinates[1]],
                "EPSG:2154",
                "EPSG:3857"
              )
            );
            self.view.setZoom(13);
          } else {
            self.view2.setCenter(
              transform(
                [result.data.coordinates[0], result.data.coordinates[1]],
                "EPSG:2154",
                "EPSG:3857"
              )
            );
            self.view2.setZoom(13);
          }
        });
      axios.post(process.env.VUE_APP_API_URL + 'inseecommunearea', data)
      .then(function (response) {
        if (mapId === 1) {
          self.cityArea = response.data
        } else {
          self.cityArea2 = response.data
        }
      })
      axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/infoparticularcommunes', data_stat)
      .then(function (response) {
        if (mapId === 1) {
          self.year11 = response.data.annee_n
          self.year12 = response.data.annee_nm1
        } else {
          self.year21 = response.data.annee_n
          self.year22 = response.data.annee_nm1
        }
      })
      axios
        .post(
          process.env.VUE_APP_API_URL + "indicateurviewer/particularcommstat",
          data_stat
        )
        .then(function (result) {
          if (mapId === 1) {
            self.currentCityStat = result.data;
            self.cityType = "commune"
          } else {
            self.currentCityStat2 = result.data;
            self.cityType2 = "commune"
          }
        });
      if (mapId === 1) {
        this.currentCity = element;
        this.cityName = element.nom
      } else {
        this.currentCity2 = element;
        this.cityName2 = element.nom
      }
      this.cityHistorized = element;
      this.citySelected = true;
      if (mapId === 1) {
        this.updateMapLayers("communes");
      } else {
        this.updateMapLayers2("communes")
      }
    },
    // Recherche un département particulier
    particularDepartementSearch (element, mapId) {
      var self = this
      var data = { 'code_dept': element.id }
        axios.post(process.env.VUE_APP_API_URL + 'indicateur_ocs/particulardepartement', data)
        .then(function (response) {
          data = { 'code_dept': response.data.code_dept }
          axios.post(process.env.VUE_APP_API_URL + 'indicateur_ocs/departementextent', data)
          .then(function (response) {
            var regExp = /\(([^)]+)\)/;
            var result = regExp.exec(response.data);
            var stringRes = result[1].split(/[ ,]+/).join(',')
            var stringExtent = '[' + stringRes + ']'
            var value = JSON.parse(stringExtent)
            proj4.defs("EPSG:2154","+proj=lcc +lat_1=49 +lat_2=44 +lat_0=46.5 +lon_0=3 +x_0=700000 +y_0=6600000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs");
            register(proj4)
            if (mapId === 1) {
              self.map.getView().fit(transformExtent(value, 'EPSG:2154', 'EPSG:3857'))
              self.cityName = element.nom
              self.updateMapLayers("dpt");
            } else if (mapId === 2) {
              self.map2.getView().fit(transformExtent(value, 'EPSG:2154', 'EPSG:3857'))
              self.cityName2 = element.nom
              self.updateMapLayers2("dpt");
            }
          })
          data = { 'code_admin': parseInt(response.data.code_dept, 10).toString() }
          axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/infoparticulardepartements', data)
          .then(function (response) {
            if (mapId === 1) {
              self.year11 = "2018-2019"
              self.year12 = response.data.annee_nm1
            } else {
              self.year21 = response.data.annee_n
              self.year22 = response.data.annee_nm1
            }
          })
        })
        axios.post(process.env.VUE_APP_API_URL + 'indicateur_ocs/departementarea', data)
        .then(function (response) {
          if (mapId === 1) {
            self.cityArea = response.data
          } else {
            self.cityArea2 = response.data
          }
        })
        axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particulardeptstat', data)
        .then(function (result) {
          if (mapId === 1) {
            self.currentCityStat = result.data
            self.cityType = "dept"
          } else if (mapId === 2) {
            self.currentCityStat2 = result.data
            self.cityType2 = "dept"
          }
        })
        if (mapId === 1) {
          this.currentCity = element;
          this.cityName = element.nom
        } else {
          this.currentCity2 = element;
          this.cityName2 = element.nom
        }
    },
    // Fonction de recherche pour les EPCI
    particularEpciSearch (element, mapId) {
      var data = { 'id_epci': element.id }
      var self = this
      axios.post(process.env.VUE_APP_API_URL + 'indicateur_ocs/particularepci', data)
      .then(function (response) {
        data = { 'id_epci': response.data.id_epci }
        // Récupère la bounding box de l'epci sélectionné 
        axios.post(process.env.VUE_APP_API_URL + 'indicateur_ocs/epciextent', data)
        .then(function (response) {
          // Filtre ce que nous souhaitons récupéré de la requête
          var regExp = /\(([^)]+)\)/;
          var result = regExp.exec(response.data);
          var stringRes = result[1].split(/[ ,]+/).join(',')
          var stringExtent = '[' + stringRes + ']'
          var value = JSON.parse(stringExtent)
          // Transforme ce que nous avons dans la bonne projection pour l'adapter à notre carte
          proj4.defs("EPSG:2154","+proj=lcc +lat_1=49 +lat_2=44 +lat_0=46.5 +lon_0=3 +x_0=700000 +y_0=6600000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs");
          register(proj4)
          // Adapte la carte à la vue passée
          if (mapId === 1) {
            self.map.getView().fit(transformExtent(value, 'EPSG:2154', 'EPSG:3857'))
            self.cityName = element.nom
            self.updateMapLayers("epci");
          } else if (mapId === 2) {
            self.map2.getView().fit(transformExtent(value, 'EPSG:2154', 'EPSG:3857'))
            self.cityName2 = element.nom
            self.updateMapLayers2("epci");
          }
        })
        // Récupère la surface de notre epci
        axios.post(process.env.VUE_APP_API_URL + 'indicateur_ocs/epciarea', data)
        .then(function (response) {
          if (mapId === 1) {
            self.cityArea = response.data
          } else {
            self.cityArea2 = response.data
          }
        })
        // Récupère diverses informations sur l'epci sélectionné
        data = { 'code_admin': parseInt(response.data.id_epci, 10).toString() }
        axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/infoparticularepci', data)
        .then(function (response) {
          if (mapId === 1) {
            self.year11 = "2018-2019"
            self.year12 = response.data.annee_nm1
          } else {
            self.year21 = response.data.annee_n
            self.year22 = response.data.annee_nm1
          }
        })
      })
      axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particularepcistat', data)
      .then(function (result) {
        if (mapId === 1) {
          self.currentCityStat = result.data
          self.cityType = "epci"
        } else if (mapId === 2) {
          self.currentCityStat2 = result.data
          self.cityType2 = "epci"
        }
      })
      if (mapId === 1) {
        this.currentCity = element;
        this.cityName = element.nom
      } else {
        this.currentCity2 = element;
        this.cityName2 = element.nom
      }
    },
    // Fonction de recherche des PNR
    particularPnrSearch(element, mapId) {
      var data = { 'id_pnr': element.id }
      var self = this
      axios.post(process.env.VUE_APP_API_URL + 'indicateur_ocs/particularpnr', data)
      .then(function (response) {
        data = { 'id_pnr': response.data.id_pnr }
        axios.post(process.env.VUE_APP_API_URL + 'indicateur_ocs/pnrextent', data)
        .then(function (response) {
          var regExp = /\(([^)]+)\)/;
          var result = regExp.exec(response.data);
          var stringRes = result[1].split(/[ ,]+/).join(',')
          var stringExtent = '[' + stringRes + ']'
          var value = JSON.parse(stringExtent)
          proj4.defs("EPSG:2154","+proj=lcc +lat_1=49 +lat_2=44 +lat_0=46.5 +lon_0=3 +x_0=700000 +y_0=6600000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs");
          register(proj4)
          if (mapId === 1) {
            self.map.getView().fit(transformExtent(value, 'EPSG:2154', 'EPSG:3857'))
            self.cityName = element.nom
            self.updateMapLayers("pnr");
          } else if (mapId === 2) {
            self.map2.getView().fit(transformExtent(value, 'EPSG:2154', 'EPSG:3857'))
            self.cityName2 = element.nom
            self.updateMapLayers2("pnr");
          }
        })
        axios.post(process.env.VUE_APP_API_URL + 'indicateur_ocs/pnrarea', data)
        .then(function (response) {
          if (mapId === 1) {
            self.cityArea = response.data
          } else {
            self.cityArea2 = response.data
          }
        })
        data = { 'code_admin': parseInt(response.data.id_pnr, 10).toString() }
        axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/infoparticularpnr', data)
        .then(function (response) {
          if (mapId === 1) {
            self.year11 = "2018-2019"
            self.year12 = response.data.annee_nm1
          } else {
            self.year21 = response.data.annee_n
            self.year22 = response.data.annee_nm1
          }
        })
      })
      axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particularpnrstat', data)
      .then(function (result) {
        if (mapId === 1) {
          self.currentCityStat = result.data
          self.cityType = "pnr"
        } else if (mapId === 2) {
          self.currentCityStat2 = result.data
          self.cityType2 = "pnr"
        }
      })
      if (mapId === 1) {
        this.currentCity = element;
        this.cityName = element.nom
      } else {
        this.currentCity2 = element;
        this.cityName2 = element.nom
      }
    },
    // Fonction de recherche pour les SCOT
    particularScotSearch(element, mapId) {
      var data = { 'id_scot': element.id }
      var self = this
      axios.post(process.env.VUE_APP_API_URL + 'indicateur_ocs/particularscot', data)
      .then(function (response) {
        data = { 'id_scot': response.data.id_scot }
        axios.post(process.env.VUE_APP_API_URL + 'indicateur_ocs/scotextent', data)
        .then(function (response) {
          var regExp = /\(([^)]+)\)/;
          var result = regExp.exec(response.data);
          var stringRes = result[1].split(/[ ,]+/).join(',')
          var stringExtent = '[' + stringRes + ']'
          var value = JSON.parse(stringExtent)
          proj4.defs("EPSG:2154","+proj=lcc +lat_1=49 +lat_2=44 +lat_0=46.5 +lon_0=3 +x_0=700000 +y_0=6600000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs");
          register(proj4)
          if (mapId === 1) {
            self.map.getView().fit(transformExtent(value, 'EPSG:2154', 'EPSG:3857'))
            self.cityName = element.nom
            self.updateMapLayers("scot");
          } else if (mapId === 2) {
            self.map2.getView().fit(transformExtent(value, 'EPSG:2154', 'EPSG:3857'))
            self.cityName2 = element.nom
            self.updateMapLayers2("scot");
          }
        })
        axios.post(process.env.VUE_APP_API_URL + 'indicateur_ocs/scotarea', data)
        .then(function (response) {
          if (mapId === 1) {
            self.cityArea = response.data
          } else {
            self.cityArea2 = response.data
          }
        })
        data = { 'code_admin': response.data.id_scot.toString() }
        axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/infoparticularscot', data)
        .then(function (response) {
          if (mapId === 1) {
            self.year11 = "2018-2019"
            self.year12 = response.data.annee_nm1
          } else {
            self.year21 = response.data.annee_n
            self.year22 = response.data.annee_nm1
          }
        })
      })
      data = { 'id_scot': element.id.toString() }
      axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particularscotstat', data)
      .then(function (result) {
        if (mapId === 1) {
          self.currentCityStat = result.data
          self.cityType = "scot"
        } else if (mapId === 2) {
          self.currentCityStat2 = result.data
          self.cityType2 = "scot"
        }
      })
      if (mapId === 1) {
        this.currentCity = element;
        this.cityName = element.nom
      } else {
        this.currentCity2 = element;
        this.cityName2 = element.nom
      }
    },
    reloadRegion (mapId) {
      var center = fromLonLat([6.87, 48.76]);
      if (mapId === 1) {
        this.cityName = "Région Grand Est"
        this.cityArea = 57705 * 1000000
        this.cityHistorized1 = {
          id: null,
          niveau: null
        }
        this.cityPopulation= {
          pmun: 5556219,
          millesime_rp: 2019
        }
        this.cityType = "region"
        this.initRegionStat(1)
        this.map.getView().setZoom(8.5)
        this.map.getView().setCenter(center)
        this.cityHistorized = {
          id: null,
          niveau: null
        }
      } else if (mapId === 2) {
        this.cityName2 = "Région Grand Est"
        this.cityArea2 = 57705 * 1000000
        this.cityHistorized2 = {
          id: null,
          niveau: null
        }
        this.cityPopulation2 = {
          pmun: 5556219,
          millesime_rp: 2019
        }
        this.cityType2 = "region"
        this.initRegionStat(2)
        this.map2.getView().setZoom(8.5)
        this.map2.getView().setCenter(center)
        this.cityHistorized = {
          id: null,
          niveau: null
        }
      }
    },
    rezoom (mapId) {
      if (mapId === 1) {
        if (this.cityHistorized1.niveau === 'commune') {
          this.particularCommuneSearch(this.cityHistorized1, 1)
        } else if (this.cityHistorized1.niveau === 'epci') {
          this.particularEpciSearch(this.cityHistorized1, 1)
        } else if (this.cityHistorized1.niveau === 'scot') {
          this.particularScotSearch(this.cityHistorized1, 1)
        } else if (this.cityHistorized1.niveau === 'pnr') {
          this.particularPnrSearch(this.cityHistorized1, 1)
        } else if (this.cityHistorized1.niveau === 'departement') {
          this.particularDepartementSearch(this.cityHistorized1, 1)
        }
      } else {
        if (this.cityHistorized2.niveau === 'commune') {
          this.particularCommuneSearch(this.cityHistorized2, 2)
        } else if (this.cityHistorized2.niveau === 'epci') {
          this.particularEpciSearch(this.cityHistorized2, 2)
        } else if (this.cityHistorized2.niveau === 'scot') {
          this.particularScotSearch(this.cityHistorized2, 2)
        } else if (this.cityHistorized2.niveau === 'pnr') {
          this.particularPnrSearch(this.cityHistorized2, 2)
        } else if (this.cityHistorized2.niveau === 'departement') {
          this.particularDepartementSearch(this.cityHistorized2, 2)
        }
      }
    },
    // Change le fond de carte
    setBasemap(value) {
      if (value === "ortho") {
        this.updateOrtho2019();
      } else if (value === "classic") {
        this.updateOsm();
      } else if (value === "ortho-2010") {
        this.updateOrtho2010()
      } else if (value === "ign") {
        this.updateIgn();
      }
    },
    // Change le fond de carte
    setBasemap2(value) {
      if (value === "ortho") {
        this.updateOrtho2019_2();
      } else if (value === "classic") {
        this.updateOsm_2();
      } else if (value === "ortho-2010") {
        this.updateOrtho2010_2()
      } else if (value === "ign") {
        this.updateIgn_2();
      }
    },
    // Affiche le popup permettant de sélectionner les statistiques à afficher
    setDisplay (value) {
      this.dialog = false
      this.displayValue = value
    },
    // Change la valeur d'opacité du layer
    setOpacity(value) {
      this.ourLayer.setOpacity(value / 100);
    },
    // Change la valeur d'opacité du layer
    setOpacity2(value) {
      this.ourLayer2.setOpacity(value / 100);
    },
    // Affiche ou cache la recherche
    toggleSearch (mapId) {
      if (mapId === 1) {
        this.displaySearch1 = !this.displaySearch1
      } else {
        this.displaySearch2 = !this.displaySearch2
      }
    },
    // Change les délimitations administratives à afficher
    updateMapLayers(value) {
      if (
        value === "communes" &&
        !this.map.getLayers().array_.includes(this.limitesCommunesLayer)
      ) {
        this.map.removeLayer(this.limitesEpciLayer);
        this.map.removeLayer(this.limitesPnrLayer);
        this.map.removeLayer(this.limitesScotLayer);
        this.map.removeLayer(this.limitesDepartementLayer);
        this.map.getLayers().insertAt(2, this.limitesCommunesLayer);
      } else if (
        value === "epci" &&
        !this.map.getLayers().array_.includes(this.limitesEpciLayer)
      ) {
        this.map.removeLayer(this.limitesCommunesLayer);
        this.map.removeLayer(this.limitesPnrLayer);
        this.map.removeLayer(this.limitesScotLayer);
        this.map.removeLayer(this.limitesDepartementLayer);
        this.map.getLayers().insertAt(2, this.limitesEpciLayer);
      } else if (
        value === "scot" &&
        !this.map.getLayers().array_.includes(this.limitesScotLayer)
      ) {
        this.map.removeLayer(this.limitesEpciLayer);
        this.map.removeLayer(this.limitesPnrLayer);
        this.map.removeLayer(this.limitesCommunesLayer);
        this.map.removeLayer(this.limitesDepartementLayer);
        this.map.getLayers().insertAt(2, this.limitesScotLayer);
      } else if (
        value === "pnr" &&
        !this.map.getLayers().array_.includes(this.limitesPnrLayer)
      ) {
        this.map.removeLayer(this.limitesEpciLayer);
        this.map.removeLayer(this.limitesCommunesLayer);
        this.map.removeLayer(this.limitesScotLayer);
        this.map.removeLayer(this.limitesDepartementLayer);
        this.map.getLayers().insertAt(2, this.limitesPnrLayer);
      } else if (
        value === "dpt" &&
        !this.map.getLayers().array_.includes(this.limitesDepartementLayer)
      ) {
        this.map.removeLayer(this.limitesEpciLayer);
        this.map.removeLayer(this.limitesPnrLayer);
        this.map.removeLayer(this.limitesScotLayer);
        this.map.removeLayer(this.limitesCommunesLayer);
        this.map.getLayers().insertAt(2, this.limitesDepartementLayer);
      } else if (value === "classic") {
        this.map.removeLayer(this.limitesEpciLayer);
        this.map.removeLayer(this.limitesPnrLayer);
        this.map.removeLayer(this.limitesScotLayer);
        this.map.removeLayer(this.limitesCommunesLayer);
        this.map.removeLayer(this.limitesDepartementLayer);
      }
    },
    // Change les délimitations administratives à afficher
    updateMapLayers2(value) {
      if (
        value === "communes" &&
        !this.map2.getLayers().array_.includes(this.limitesCommunesLayer2)
      ) {
        this.map2.removeLayer(this.limitesEpciLayer2);
        this.map2.removeLayer(this.limitesPnrLayer2);
        this.map2.removeLayer(this.limitesScotLayer2);
        this.map2.removeLayer(this.limitesDepartementLayer2);
        this.map2.getLayers().insertAt(2, this.limitesCommunesLayer2);
      } else if (
        value === "epci" &&
        !this.map2.getLayers().array_.includes(this.limitesEpciLayer2)
      ) {
        this.map2.removeLayer(this.limitesCommunesLayer2);
        this.map2.removeLayer(this.limitesPnrLayer2);
        this.map2.removeLayer(this.limitesScotLayer2);
        this.map2.removeLayer(this.limitesDepartementLayer2);
        this.map2.getLayers().insertAt(2, this.limitesEpciLayer2);
      } else if (
        value === "scot" &&
        !this.map2.getLayers().array_.includes(this.limitesScotLayer2)
      ) {
        this.map2.removeLayer(this.limitesEpciLayer2);
        this.map2.removeLayer(this.limitesPnrLayer2);
        this.map2.removeLayer(this.limitesCommunesLayer2);
        this.map2.removeLayer(this.limitesDepartementLayer2);
        this.map2.getLayers().insertAt(2, this.limitesScotLayer2);
      } else if (
        value === "pnr" &&
        !this.map2.getLayers().array_.includes(this.limitesPnrLayer2)
      ) {
        this.map2.removeLayer(this.limitesEpciLayer2);
        this.map2.removeLayer(this.limitesCommunesLayer2);
        this.map2.removeLayer(this.limitesScotLayer2);
        this.map2.removeLayer(this.limitesDepartementLayer2);
        this.map2.getLayers().insertAt(2, this.limitesPnrLayer2);
      } else if (
        value === "dpt" &&
        !this.map2.getLayers().array_.includes(this.limitesDepartementLayer2)
      ) {
        this.map2.removeLayer(this.limitesEpciLayer2);
        this.map2.removeLayer(this.limitesPnrLayer2);
        this.map2.removeLayer(this.limitesScotLayer2);
        this.map2.removeLayer(this.limitesCommunesLayer2);
        this.map2.getLayers().insertAt(2, this.limitesDepartementLayer2);
      } else if (value === "classic") {
        this.map2.removeLayer(this.limitesEpciLayer2);
        this.map2.removeLayer(this.limitesPnrLayer2);
        this.map2.removeLayer(this.limitesScotLayer2);
        this.map2.removeLayer(this.limitesCommunesLayer2);
        this.map2.removeLayer(this.limitesDepartementLayer2);
      }
    },
    // Remplace le fond de carte actuel pour le fond de carte orthophotographique 2010
    updateOrtho2010_2() {
      var self = this;
      var parser = new WMTSCapabilities();
      axios
        .get(
          "https://www.datagrandest.fr/geoserver/gwc/service/wmts?REQUEST=getcapabilities"
        )
        .then(function (response) {
          var result = parser.read(response.data);
          var options = optionsFromCapabilities(result, {
            layer: "geograndest:ORTHO_2007_2010_GRAND_EST",
            matrixSet: "EPSG:3857",
          });
          self.basemap2.setSource(new WMTS(options));
          self.map2.changed();
        });
    },
    // Remplace le fond de carte actuel pour le fond de carte orthophotographique 2019
    updateOrtho2019_2() {
      var self = this;
      var parser = new WMTSCapabilities();
      axios
        .get(
          "https://www.datagrandest.fr/geoserver/gwc/service/wmts?REQUEST=getcapabilities"
        )
        .then(function (response) {
          var result = parser.read(response.data);
          var options = optionsFromCapabilities(result, {
            layer: "geograndest:GGE_ORTHO_RVB_ACTUELLE",
            matrixSet: "EPSG:3857",
          });
          self.basemap2.setSource(new WMTS(options));
          self.map2.changed();
        });
    },
    // Remplace le fond de carte actuel pour le fond de carte OSM
    updateOsm_2() {
      var self = this;
      var parser = new WMTSCapabilities();
      axios
        .get(
          "https://osm.datagrandest.fr/mapcache/wmts?service=WMTS&request=GetCapabilities"
        )
        .then(function (response) {
          var result = parser.read(response.data);
          var options = optionsFromCapabilities(result, {
            layer: "pure",
            matrixSet: "EPSG:3857",
          });
          self.basemap2.setSource(new WMTS(options));
          self.map2.changed();
        });
    },
    // Remplace le fond de carte actuel pour le fond de carte orthophotographique 2010
    updateOrtho2010() {
      var self = this;
      var parser = new WMTSCapabilities();
      axios
        .get(
          "https://www.datagrandest.fr/geoserver/gwc/service/wmts?REQUEST=getcapabilities"
        )
        .then(function (response) {
          var result = parser.read(response.data);
          var options = optionsFromCapabilities(result, {
            layer: "geograndest:ORTHO_2007_2010_GRAND_EST",
            matrixSet: "EPSG:3857",
          });
          self.basemap.setSource(new WMTS(options));
          self.map.changed();
        });
    },
    // Remplace le fond de carte actuel pour le fond de carte orthophotographique 2019
    updateOrtho2019() {
      var self = this;
      var parser = new WMTSCapabilities();
      axios
        .get(
          "https://www.datagrandest.fr/geoserver/gwc/service/wmts?REQUEST=getcapabilities"
        )
        .then(function (response) {
          var result = parser.read(response.data);
          var options = optionsFromCapabilities(result, {
            layer: "geograndest:GGE_ORTHO_RVB_ACTUELLE",
            matrixSet: "EPSG:3857",
          });
          self.basemap.setSource(new WMTS(options));
          self.map.changed();
        });
    },
    // Remplace le fond de carte actuel pour le fond de carte OSM
    updateOsm() {
      var self = this;
      var parser = new WMTSCapabilities();
      axios
        .get(
          "https://osm.datagrandest.fr/mapcache/wmts?service=WMTS&request=GetCapabilities"
        )
        .then(function (response) {
          var result = parser.read(response.data);
          var options = optionsFromCapabilities(result, {
            layer: "pure",
            matrixSet: "EPSG:3857",
          });
          self.basemap.setSource(new WMTS(options));
          self.map.changed();
        });
    },
    // Fonction qui attribue un style à un polygone pour l'info-bulle
    styleFunction (feature) {
      const styles = {
        'Polygon': new Style({
          stroke: new Stroke({
            color: 'blue',
            lineDash: [4],
            width: 3,
          }),
          fill: new Fill({
            color: 'rgba(0, 0, 255, 0.1)',
          }),
        }),
        'MultiPolygon': new Style({
          stroke: new Stroke({
            color: 'yellow',
            width: 1,
          }),
          fill: new Fill({
            color: 'rgba(255, 255, 0, 0.1)',
          }),
        }),
      }
      return styles[feature.getGeometry().getType()];
    },
    // Affiche ou non l'info-bulle
    toggleInfos (infos, mapId) {
      if (infos) {
        if (mapId === 1) {
          this.map.on("click", this.handleOverlay)
        } else {
          this.map2.on("click", this.handleOverlay2)
        }
      } else {
        if (mapId === 1) {
          this.map.un("click", this.handleOverlay)
          this.vectorSource.clear()
          this.overlay.setPosition(undefined);
        } else {
          this.map2.un("click", this.handleOverlay2)
          this.vectorSource2.clear()
          this.overlay2.setPosition(undefined);
        }
      }
    },
    // Affiche ou non un drawer d'option
    toggleOption (data) {
      this.optionDisplay = data.option
      if (data.map === 1) {
        this.displayOptionDrawer1 = !this.displayOptionDrawer1
      } else {
        this.displayOptionDrawer2 = !this.displayOptionDrawer2
      }
    }
  },
  watch: {
    // Garde constamment les statistiques à jour
    currentCityStat () {
      this.items = [
        {
          number: this.currentCityStat.arti_s_19,
          unit: "ha",
          tx: this.currentCityStat.arti_tx_19,
          subNumber: this.currentCityStat.arti_evo,
          subTx: this.currentCityStat.arti_var,
          description: "Artificialisé",
          img: "$vuetify.icon.artificialise",
          color: "#932bc9",
          // img: "forest_color.png",
        },
        {
          number: this.currentCityStat.imp_bati_s_19,
          unit: "ha",
          tx: this.currentCityStat.imp_bati_tx_19,
          subNumber: this.currentCityStat.imp_bati_evo,
          subTx: this.currentCityStat.imp_bati_var,
          description: "Imperméable bâti",
          img: "$vuetify.icon.impermeable_bati",
          color: "#f45b25",
        },
        {
          number: this.currentCityStat.imp_non_bati_s_19,
          unit: "ha",
          tx: this.currentCityStat.imp_non_bati_tx_19,
          subNumber: this.currentCityStat.imp_non_bati_evo,
          subTx: this.currentCityStat.imp_non_bati_var,
          description: "Imperméable non bâti",
          img: "$vuetify.icon.impermeable_non_bati",
          color: "#ff9292",
        },
        {
          number: this.currentCityStat.perm_s_19,
          unit: "ha",
          tx: this.currentCityStat.perm_tx_19,
          subNumber: this.currentCityStat.perm_evo,
          subTx: this.currentCityStat.perm_var,
          description: "Perméable urbain",
          img: "$vuetify.icon.permeable_urbain",
          color: "#96af38",
        },
        {
          number: this.currentCityStat.agri_s_19,
          unit: "ha",
          tx: this.currentCityStat.agri_tx_19,
          subNumber: this.currentCityStat.agri_evo,
          subTx: this.currentCityStat.agri_var,
          description: "Agricole",
          img: "$vuetify.icon.agricole",
          color: "#e0a500",
        },
        {
          number: this.currentCityStat.foret_s_19,
          unit: "ha",
          tx: this.currentCityStat.foret_tx_19,
          subNumber: this.currentCityStat.foret_evo,
          subTx: this.currentCityStat.foret_var,
          description: "Forestier et semi-naturel",
          img: "$vuetify.icon.forestier",
          color: "#059e43",
        },
        {
          number: this.currentCityStat.zh_s_19,
          unit: "ha",
          tx: this.currentCityStat.zh_tx_19,
          subNumber: this.currentCityStat.zh_evo,
          subTx: this.currentCityStat.zh_var,
          description: "Zone humide",
          img: "$vuetify.icon.zone_humide",
          color: "#2cc4c4",
        },
        {
          number: this.currentCityStat.eau_s_19,
          unit: "ha",
          tx: this.currentCityStat.eau_tx_19,
          subNumber: this.currentCityStat.eau_evo,
          subTx: this.currentCityStat.eau_var,
          description: "Eau",
          img: "$vuetify.icon.eau",
          color: "#2497ff",
        }
      ]
    },
    // Garde constamment les statistiques à jour
    currentCityStat2 () {
      this.items2 = [
        {
          number: this.currentCityStat2.arti_s_19,
          unit: "ha",
          tx: this.currentCityStat2.arti_tx_19,
          subNumber: this.currentCityStat2.arti_evo,
          subTx: this.currentCityStat2.arti_var,
          description: "Artificialisé",
          img: "$vuetify.icon.artificialise",
          color: "#932bc9",
          // img: "forest_color.png",
        },
        {
          number: this.currentCityStat2.imp_bati_s_19,
          unit: "ha",
          tx: this.currentCityStat2.imp_bati_tx_19,
          subNumber: this.currentCityStat2.imp_bati_evo,
          subTx: this.currentCityStat2.imp_bati_var,
          description: "Imperméable bâti",
          img: "$vuetify.icon.impermeable_bati",
          color: "#f45b25",
        },
        {
          number: this.currentCityStat2.imp_non_bati_s_19,
          unit: "ha",
          tx: this.currentCityStat2.imp_non_bati_tx_19,
          subNumber: this.currentCityStat2.imp_non_bati_evo,
          subTx: this.currentCityStat2.imp_non_bati_var,
          description: "Imperméable non bâti",
          img: "$vuetify.icon.impermeable_non_bati",
          color: "#ff9292",
        },
        {
          number: this.currentCityStat2.perm_s_19,
          unit: "ha",
          tx: this.currentCityStat2.perm_tx_19,
          subNumber: this.currentCityStat2.perm_evo,
          subTx: this.currentCityStat2.perm_var,
          description: "Perméable urbain",
          img: "$vuetify.icon.permeable_urbain",
          color: "#96af38",
        },
        {
          number: this.currentCityStat2.agri_s_19,
          unit: "ha",
          tx: this.currentCityStat2.agri_tx_19,
          subNumber: this.currentCityStat2.agri_evo,
          subTx: this.currentCityStat2.agri_var,
          description: "Agricole",
          img: "$vuetify.icon.agricole",
          color: "#e0a500",
        },
        {
          number: this.currentCityStat2.foret_s_19,
          unit: "ha",
          tx: this.currentCityStat2.foret_tx_19,
          subNumber: this.currentCityStat2.foret_evo,
          subTx: this.currentCityStat2.foret_var,
          description: "Forestier et semi-naturel",
          img: "$vuetify.icon.forestier",
          color: "#059e43",
        },
        {
          number: this.currentCityStat2.zh_s_19,
          unit: "ha",
          tx: this.currentCityStat2.zh_tx_19,
          subNumber: this.currentCityStat2.zh_evo,
          subTx: this.currentCityStat2.zh_var,
          description: "Zone humide",
          img: "$vuetify.icon.zone_humide",
          color: "#2cc4c4",
        },
        {
          number: this.currentCityStat2.eau_s_19,
          unit: "ha",
          tx: this.currentCityStat2.eau_tx_19,
          subNumber: this.currentCityStat2.eau_evo,
          subTx: this.currentCityStat2.eau_var,
          description: "Eau",
          img: "$vuetify.icon.eau",
          color: "#2497ff",
        }
      ]
    },
    currentRegionStat () {
      this.items = [
        {
          number: this.currentRegionStat.arti_s_19,
          unit: "ha",
          tx: this.currentRegionStat.arti_tx_19,
          subNumber: this.currentRegionStat.arti_evo,
          subTx: this.currentRegionStat.arti_var,
          description: "Artificialisé",
          img: "$vuetify.icon.artificialise",
          color: "#932bc9",
          // img: "forest_color.png",
        },
        {
          number: this.currentRegionStat.imp_bati_s_19,
          unit: "ha",
          tx: this.currentRegionStat.imp_bati_tx_19,
          subNumber: this.currentRegionStat.imp_bati_evo,
          subTx: this.currentRegionStat.imp_bati_var,
          description: "Imperméable bâti",
          img: "$vuetify.icon.impermeable_bati",
          color: "#f45b25",
        },
        {
          number: this.currentRegionStat.imp_non_bati_s_19,
          unit: "ha",
          tx: this.currentRegionStat.imp_non_bati_tx_19,
          subNumber: this.currentRegionStat.imp_non_bati_evo,
          subTx: this.currentRegionStat.imp_non_bati_var,
          description: "Imperméable non bâti",
          img: "$vuetify.icon.impermeable_non_bati",
          color: "#ff9292",
        },
        {
          number: this.currentRegionStat.perm_s_19,
          unit: "ha",
          tx: this.currentRegionStat.perm_tx_19,
          subNumber: this.currentRegionStat.perm_evo,
          subTx: this.currentRegionStat.perm_var,
          description: "Perméable urbain",
          img: "$vuetify.icon.permeable_urbain",
          color: "#96af38",
        },
        {
          number: this.currentRegionStat.agri_s_19,
          unit: "ha",
          tx: this.currentRegionStat.agri_tx_19,
          subNumber: this.currentRegionStat.agri_evo,
          subTx: this.currentRegionStat.agri_var,
          description: "Agricole",
          img: "$vuetify.icon.agricole",
          color: "#e0a500",
        },
        {
          number: this.currentRegionStat.foret_s_19,
          unit: "ha",
          tx: this.currentRegionStat.foret_tx_19,
          subNumber: this.currentRegionStat.foret_evo,
          subTx: this.currentRegionStat.foret_var,
          description: "Forestier et semi-naturel",
          img: "$vuetify.icon.forestier",
          color: "#059e43",
        },
        {
          number: this.currentRegionStat.zh_s_19,
          unit: "ha",
          tx: this.currentRegionStat.zh_tx_19,
          subNumber: this.currentRegionStat.zh_evo,
          subTx: this.currentRegionStat.zh_var,
          description: "Zone humide",
          img: "$vuetify.icon.zone_humide",
          color: "#2cc4c4",
        },
        {
          number: this.currentRegionStat.eau_s_19,
          unit: "ha",
          tx: this.currentRegionStat.eau_tx_19,
          subNumber: this.currentRegionStat.eau_evo,
          subTx: this.currentRegionStat.eau_var,
          description: "Eau",
          img: "$vuetify.icon.eau",
          color: "#2497ff",
        }
      ]
    },
    currentRegionStat2() {
      this.items2 = [
        {
          number: this.currentRegionStat2.arti_s_19,
          unit: "ha",
          tx: this.currentRegionStat2.arti_tx_19,
          subNumber: this.currentRegionStat2.arti_evo,
          subTx: this.currentRegionStat2.arti_var,
          description: "Artificialisé",
          img: "$vuetify.icon.artificialise",
          color: "#932bc9",
          // img: "forest_color.png",
        },
        {
          number: this.currentRegionStat2.imp_bati_s_19,
          unit: "ha",
          tx: this.currentRegionStat2.imp_bati_tx_19,
          subNumber: this.currentRegionStat2.imp_bati_evo,
          subTx: this.currentRegionStat2.imp_bati_var,
          description: "Imperméable bâti",
          img: "$vuetify.icon.impermeable_bati",
          color: "#f45b25",
        },
        {
          number: this.currentRegionStat2.imp_non_bati_s_19,
          unit: "ha",
          tx: this.currentRegionStat2.imp_non_bati_tx_19,
          subNumber: this.currentRegionStat2.imp_non_bati_evo,
          subTx: this.currentRegionStat2.imp_non_bati_var,
          description: "Imperméable non bâti",
          img: "$vuetify.icon.impermeable_non_bati",
          color: "#ff9292",
        },
        {
          number: this.currentRegionStat2.perm_s_19,
          unit: "ha",
          tx: this.currentRegionStat2.perm_tx_19,
          subNumber: this.currentRegionStat2.perm_evo,
          subTx: this.currentRegionStat2.perm_var,
          description: "Perméable urbain",
          img: "$vuetify.icon.permeable_urbain",
          color: "#96af38",
        },
        {
          number: this.currentRegionStat2.agri_s_19,
          unit: "ha",
          tx: this.currentRegionStat2.agri_tx_19,
          subNumber: this.currentRegionStat2.agri_evo,
          subTx: this.currentRegionStat2.agri_var,
          description: "Agricole",
          img: "$vuetify.icon.agricole",
          color: "#e0a500",
        },
        {
          number: this.currentRegionStat2.foret_s_19,
          unit: "ha",
          tx: this.currentRegionStat2.foret_tx_19,
          subNumber: this.currentRegionStat2.foret_evo,
          subTx: this.currentRegionStat2.foret_var,
          description: "Forestier et semi-naturel",
          img: "$vuetify.icon.forestier",
          color: "#059e43",
        },
        {
          number: this.currentRegionStat2.zh_s_19,
          unit: "ha",
          tx: this.currentRegionStat2.zh_tx_19,
          subNumber: this.currentRegionStat2.zh_evo,
          subTx: this.currentRegionStat2.zh_var,
          description: "Zone humide",
          img: "$vuetify.icon.zone_humide",
          color: "#2cc4c4",
        },
        {
          number: this.currentRegionStat2.eau_s_19,
          unit: "ha",
          tx: this.currentRegionStat2.eau_tx_19,
          subNumber: this.currentRegionStat2.eau_evo,
          subTx: this.currentRegionStat2.eau_var,
          description: "Eau",
          img: "$vuetify.icon.eau",
          color: "#2497ff",
        }
      ]
    },
    urlLayer(newVal) {
      var source = new XYZ({
        url: newVal,
      });
      this.ourLayer.setSource(source);
    },
    urlLayer2(newVal) {
      var source = new XYZ({
        url: newVal,
      });
      this.ourLayer2.setSource(source);
    }
  }
};
</script>

<style scoped>
.compare-map {
  position: relative;
  height: 70vh;
  width: 100%;
  margin-bottom: 16px;
  z-index: 1;
  /* background-color: #e0e0e0; */
}

.ol-scale-line {
  left: 60px;
}

.ol-zoom {
  left: 60px;
}

.ol-popup {
  position: absolute;
  background-color: white;
  box-shadow: 0 1px 4px rgba(0,0,0,0.2);
  border-radius: 10px;
  border: 1px solid #cccccc;
  bottom: 12px;
  left: -50px;
  min-width: 280px;
  opacity: 0.85
}
.ol-popup:after, .ol-popup:before {
  top: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.ol-popup:after {
  border-top-color: white;
  border-width: 10px;
  left: 48px;
  margin-left: -10px;
}
.ol-popup:before {
  border-top-color: #cccccc;
  border-width: 11px;
  left: 48px;
  margin-left: -11px;
}
.ol-popup-closer {
  position: absolute;
  top: -12px;
  right: -12px;
}

.ol-popup-swipe {
  min-width: 400px;
}
</style>