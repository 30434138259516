<template>
  <v-fab-transition>
    <v-btn
      v-show="fab"
      v-scroll="onScroll"
      class="up-btn-style"
      elevation="2"
      fab
      color="#4473c5"
      dark
      large
      transition="fab-transition"
      @click="toTop"
    >
      <v-icon>mdi-chevron-up</v-icon>
    </v-btn>
  </v-fab-transition>
</template>

<script>
export default {
  name: "UpBtn",
  props: {
    custom: {
      type: String,
      required: false,
      default: "",
    },
  },
  data() {
    return {
      fab: false,
    };
  },
  methods: {
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 20;
    },
    toTop() {
      this.$vuetify.goTo(this.custom);
    },
  },
};
</script>

<style scoped>
.up-btn-style {
  position: fixed;
  right: 2%;
  bottom: 2%;
  z-index: 9999;
}
</style>
