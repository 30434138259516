<!-- Ce composant gère la recherche d'une carte pour la page comparer -->
<template>
  <v-container class="compare-search mr-12">
    <div>
      <v-autocomplete
        outlined
        label="Rechercher"
        v-model="citySelected"
        :items="citiesSuggested"
        item-text="nom"
        item-value="id"
        :search-input.sync="search"
        no-filter
        hide-no-data
        return-object
      >
        <template slot="item" slot-scope="citiesSuggested">
          {{ citiesSuggested.item.nom }} <span class="ml-1 mt-1" style="color: grey; font-size: 12px"> -  {{ citiesSuggested.item.niveau }}</span>
        </template>
      </v-autocomplete>
    </div>
  </v-container>
</template>

<script>
import axios from "axios"

export default {
  name: "CompareSearch",
  data () {
    return {
      citySelected: null,
      citiesSuggested: [],
      // Contenu de la recherche
      search: '',
    }
  },
  methods: {
    // Supprime les villes suggérées par l'autocomplete
    clearEntries() {
      this.citiesSuggested = []
    },
    // Ajoute un timer pour éviter de balancer un nombre important de requêtes
    fetchEntriesDebounced (city) {
      var self = this
      clearTimeout(this._searchTimerId)
      this._searchTimerId = setTimeout(() => {
        self.fetchEntries(city)
      }, 1000)
    },
    // Interroge la base de donnée en fonction de ce qu'à taper l'utilisateur
    fetchEntries (city) {
      // Si la ville n'est pas null
      if (city !== null) {
        // On réinitialise les villes suggérées par l'autocomplete
        this.citiesSuggested = []
        // Sauvegarde le contexte de l'application
        var self = this
        // Affiche une animation de chargement
        this.isLoading = true
        // Création de la data
        var data = { 'value': this.slugify(city).toUpperCase() }
        // Requête la base de donnée
        axios.post(process.env.VUE_APP_API_URL + 'search2', data)
        .then(function (results) {
          results.data.forEach(function (result) {
            self.citiesSuggested.push(result)
          })
        })
      }
    },
    // Format de retour de la recherche (nom_communes (numéro_departement))
    format (item) {
      return item.nom_com_min + " (" + item.code_dept + ")"
    },
    // Supprime les accents
    slugify (str) {
      var map = {
        'a' : 'á|à|ã|â|À|Á|Ã|Â',
        'e' : 'é|è|ê|É|È|Ê',
        'i' : 'í|ì|î|Í|Ì|Î',
        'o' : 'ó|ò|ô|õ|Ó|Ò|Ô|Õ',
        'u' : 'ú|ù|û|ü|Ú|Ù|Û|Ü',
        'c' : 'ç|Ç',
        'n' : 'ñ|Ñ'
      };
        
      for (var pattern in map) {
        str = str.replace(new RegExp(map[pattern], 'g'), pattern);
      }

      return str;
    }
  },
  watch: {
    // Dès qu'une ville est sélectionnée, nous balançons un évènement pour signaler le changement sur la carte
    citySelected (val) {
      this.$emit('citySelected', val)
    },
    // Dès que le contenu de la recherche change, balance une requête
    // FONCTION IMPORTANTE POUR CE COMPOSANT
    search (city) {
      // Empêche le déclenchement de la requête si le contenu est vide
      if (!city) {
        return
      }

      // Efface les entrées suggérées par l'autocomplete
      this.clearEntries()
      // Déclenche l'animation de chargement
      this.isLoading = true
      // Déclenche la requête
      this.fetchEntriesDebounced(city)
    },
  }
}
</script>

<style scoped>
.compare-search {
  background-color: white;
  height: 85px;
  border-radius: 0 0 15px 15px;
  position: absolute;
  right: 0;
  top: 67px;
  max-width: 20vw;
  box-shadow: 0 4px 5px 0 rgba(0,0,0,.14);
  opacity: 0.9;
  z-index: 5
}
</style>