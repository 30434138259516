<template>
  <v-container class="pt-0">
    <v-tabs v-model="onglet" height="30px">
      <v-tab>Nomenclature</v-tab>
      <v-tab>Fond de carte</v-tab>
      <v-tab>Délimitations administratives</v-tab>
    </v-tabs>

    <v-tabs-items v-model="onglet">
      <v-tab-item>
        <!-- Sélection du niveau de nomenclature -->
        <div style="overflow-x: hidden; overflow-y: auto; height: 37vh">
          <v-row dense style="flex-wrap: nowrap">
            <v-col class="mb-0 mt-5">
              <v-select
                v-model="millesimeSelected"
                :items="millesimeList"
                label="Millésime"
                style="max-width: 8vw"
                outlined
              ></v-select>
            </v-col>
          </v-row>
          <p class="mb-0">Opacité</p>
          <!-- Slider permettant la sélection d'une valeur d'opacité allant de 0 à 100 -->
          <v-slider
            v-model="slider"
            style="max-width: 50%"
            hide-details
          ></v-slider>
          <!-- Affichage de la valeur sélectionnée -->
          <v-row dense justify="center">
            <v-col cols="6">
              <p class="slider-value"><b>{{ slider }} %</b></p>
            </v-col>
          </v-row>
          <v-radio-group v-model="level" class="mt-0">
            <v-radio
              class="mb-0"
              :value="1"
            >
              <template v-slot:label>
                <div style="color: black">Niveau 1</div>
              </template>
            </v-radio>
            <v-row dense class="mb-2">
              <v-col dense>
                <p class="ma-0 pa-0" style="font-size: 14px">5 postes de description de l'occupation du sol
                <v-btn icon @click="displayPopupLegend(1)">
                  <v-icon>mdi-information-outline</v-icon>
                </v-btn>
                </p>
              </v-col>
            </v-row>
            <v-radio
              class="mb-0"
              :value="2"
            >
              <template v-slot:label>
                <div style="color: black">Niveau 2</div>
              </template>
            </v-radio>
            <v-row dense class="mb-2">
              <v-col dense>
                <p class="ma-0 pa-0" style="font-size: 14px">15 postes de description de l'occupation du sol
                <v-btn icon @click="displayPopupLegend(2)">
                  <v-icon>mdi-information-outline</v-icon>
                </v-btn>
                </p>
              </v-col>
            </v-row>
            <v-radio
              class="mb-0"
              :value="3"
            >
              <template v-slot:label>
                <div style="color: black">Niveau 3</div>
              </template>
            </v-radio>
            <v-row dense class="mb-2">
              <v-col dense>
                <p class="ma-0 pa-0" style="font-size: 14px">38 postes de description de l'occupation du sol
                <v-btn icon @click="displayPopupLegend(3)">
                  <v-icon>mdi-information-outline</v-icon>
                </v-btn>
                </p>
              </v-col>
            </v-row>
            <v-radio
              class="mb-0"
              :value="4"
            >
              <template v-slot:label>
                <div style="color: black">Niveau 4</div>
              </template>
            </v-radio>
            <v-row dense class="mb-2">
              <v-col dense>
                <p class="ma-0 pa-0" style="font-size: 14px">53 postes de description de l'occupation du sol
                <v-btn icon @click="displayPopupLegend(4)">
                  <v-icon>mdi-information-outline</v-icon>
                </v-btn>
                </p>
              </v-col>
            </v-row>
            <v-radio
              label='Niveau 5'
              class="mb-0"
              :value="5"
            >
              <template v-slot:label>
                <div style="color: black">Niveau 5</div>
              </template>
            </v-radio>
            <v-row dense class="mb-2">
              <v-col dense>
                <p class="ma-0 pa-0" style="font-size: 14px">3 postes de description de l'occupation du sol - uniquement sur les territoires artificialisés
                <v-btn icon @click="displayPopupLegend(5)">
                  <v-icon>mdi-information-outline</v-icon>
                </v-btn>
                </p>
              </v-col>
            </v-row>
          </v-radio-group>
        </div>
      </v-tab-item>

      <!-- Choix du fond de carte -->
      <v-tab-item>
        <v-list dense style="overflow-x: hidden; overflow-y: auto; height: 37vh">
          <v-list-item link @click="setBasemap('classic')">
            <v-list-item-content>
              <v-row>
                <!-- Image -->
                <v-col cols="3">
                  <v-img
                    id="osm-basemap"
                    height="92px"
                    width="92px"
                    :src="require('@/assets/images/basemap/osm.png')"
                    :lazy-src="
                      require('@/assets/images/basemap/osm.png')
                    "
                    :class="osm ? 'rounded-circle basemap-selected' : 'rounded-circle'"
                  />
                </v-col>
                <!-- Section descriptive -->
                <v-col cols="7">
                  <!-- Titre -->
                  <p style="font-weight: bold; text-align: left">Flux OSM</p>
                  <!-- Description -->
                  <p style="text-align: left; font-size: small">
                    Fond de carte communautaire et Open-Source
                  </p>
                </v-col>
              </v-row>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link @click="setBasemap('ortho')">
            <v-list-item-content>
              <v-row>
                <v-col cols="3">
                  <v-img
                    id="ortho-basemap"
                    height="92px"
                    width="92px"
                    :src="require('@/assets/images/basemap/ortho.png')"
                    :lazy-src="
                      require('@/assets/images/basemap/ortho.png')
                    "
                    :class="ortho ? 'rounded-circle basemap-selected' : 'rounded-circle'"
                  />
                </v-col>
                <v-col cols="7">
                  <p style="font-weight: bold; text-align: left">Flux Orthophotographique 2018/2019</p>
                  <p style="text-align: left; font-size: small">
                    Fond de carte produit et mis à disposition par DataGrandEst (Millésime: 2018/2019)
                  </p>
                </v-col>
              </v-row>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link @click="setBasemap('ortho-2010')">
            <v-list-item-content>
              <v-row>
                <v-col cols="3">
                  <v-img
                    id="ortho-2010-basemap"
                    height="92px"
                    width="92px"
                    :src="require('@/assets/images/basemap/ortho-2010.png')"
                    :lazy-src="
                      require('@/assets/images/basemap/ortho-2010.png')
                    "
                    :class="ortho10 ? 'rounded-circle basemap-selected' : 'rounded-circle'"
                  />
                </v-col>
                <v-col cols="7">
                  <p style="font-weight: bold; text-align: left">Flux Orthophotographique 2007-2010</p>
                  <p style="text-align: left; font-size: small">
                    Fond de carte produit et mis à disposition par DataGrandEst (Millésime: 2007-2010)
                  </p>
                </v-col>
              </v-row>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-tab-item>

      <!-- Délimitations administratives -->
      <v-tab-item>
        <v-list style="padding: 0; overflow-x: hidden; overflow-y: auto; height: 37vh">
      <!-- Classique -->
          <v-list-item link @click="setMapStyle('classic')">
            <v-list-item-content style="padding: 0; padding-top: 3px">
              <v-row>
                <v-col cols="3">
                  <v-img
                    height="92px"
                    width="92px"
                    :src="require('@/assets/images/numbers_logo/puce_region.png')"
                    :lazy-src="
                      require('@/assets/images/numbers_logo/puce_region.png')
                    "
                    class="rounded-circle"
                  />
                </v-col>
                <v-col cols="7">
                  <p style="font-weight: bold; text-align: left">Classique</p>
                  <p style="text-align: left; font-size: small">
                    Désactive les contours
                  </p>
                </v-col>
              </v-row>
            </v-list-item-content>
          </v-list-item>

          <!-- Communes -->
          <v-list-item link @click="setMapStyle('communes')">
            <v-list-item-content style="padding: 0; padding-top: 3px">
              <v-row>
                <v-col cols="3">
                  <v-img
                    height="92px"
                    width="92px"
                    :src="require('@/assets/images/numbers_logo/puce_comm.png')"
                    :lazy-src="
                      require('@/assets/images/numbers_logo/puce_comm.png')
                    "
                    class="rounded-circle"
                  />
                </v-col>
                <v-col cols="7">
                  <p style="font-weight: bold; text-align: left">Communes</p>
                  <p style="text-align: left; font-size: small">
                    Affiche les contours des communes
                  </p>
                </v-col>
              </v-row>
            </v-list-item-content>
          </v-list-item>

          <!-- EPCI -->
          <v-list-item link @click="setMapStyle('epci')">
            <v-list-item-content style="padding: 0">
              <v-row>
                <v-col cols="3">
                  <v-img
                    height="92px"
                    width="92px"
                    :src="require('@/assets/images/numbers_logo/puce_epci.png')"
                    :lazy-src="
                      require('@/assets/images/numbers_logo/puce_epci.png')
                    "
                    class="rounded-circle"
                  />
                </v-col>
                <v-col cols="7">
                  <p style="font-weight: bold; text-align: left">EPCI</p>
                  <p style="text-align: left; font-size: small">
                    Affiche les contours des EPCI
                  </p>
                </v-col>
              </v-row>
            </v-list-item-content>
          </v-list-item>

          <!-- PNR -->
          <v-list-item link @click="setMapStyle('pnr')">
            <v-list-item-content style="padding: 0">
              <v-row>
                <v-col cols="3">
                  <v-img
                    height="92px"
                    width="92px"
                    :src="require('@/assets/images/numbers_logo/puce_pnr.png')"
                    :lazy-src="require('@/assets/images/numbers_logo/puce_pnr.png')"
                    class="rounded-circle"
                  />
                </v-col>
                <v-col cols="7">
                  <p style="font-weight: bold; text-align: left">PNR</p>
                  <p style="text-align: left; font-size: small">
                    Affiche les contours des PNR
                  </p>
                </v-col>
              </v-row>
            </v-list-item-content>
          </v-list-item>

          <!-- SCOT -->
          <v-list-item link @click="setMapStyle('scot')">
            <v-list-item-content style="padding: 0">
              <v-row>
                <v-col cols="3">
                  <v-img
                    height="92px"
                    width="92px"
                    :src="require('@/assets/images/numbers_logo/puce_scot.png')"
                    :lazy-src="
                      require('@/assets/images/numbers_logo/puce_scot.png')
                    "
                    class="rounded-circle"
                  />
                </v-col>
                <v-col cols="7">
                  <p style="font-weight: bold; text-align: left">SCOT</p>
                  <p style="text-align: left; font-size: small">
                    Affiche les contours des SCOT
                  </p>
                </v-col>
              </v-row>
            </v-list-item-content>
          </v-list-item>

          <!-- Département -->
          <v-list-item link @click="setMapStyle('dpt')">
            <v-list-item-content style="padding: 0">
              <v-row>
                <v-col cols="3">
                  <v-img
                    height="92px"
                    width="92px"
                    :src="require('@/assets/images/numbers_logo/puce_dept.png')"
                    :lazy-src="
                      require('@/assets/images/numbers_logo/puce_dept.png')
                    "
                    class="rounded-circle"
                  />
                </v-col>
                <v-col cols="7">
                  <p style="font-weight: bold; text-align: left">Départements</p>
                  <p style="text-align: left; font-size: small">
                    Affiche les contours des départements
                  </p>
                </v-col>
              </v-row>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-tab-item>
    </v-tabs-items>

    <!-- Popup de la légende (Possibilité d'amélioration en externalisant le composant) -->
    <v-dialog v-model="dialog" persistent max-width="35vw">
      <v-card class="dialog-card">
        <v-btn style="float: right" icon @click="closeDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-card-title>
          Légende
        </v-card-title>
        <v-img
          class="ma-1"
          :src="require('@/assets/legends/niveau' + levelPopup + '.png')"
          :lazy-src="require('@/assets/legends/niveau' + levelPopup + '.png')"
          max-width="25vw"
        ></v-img>
        <v-btn text class="button-style" download :href="'img/nomenclature_niv' + levelPopup + '.jpg'">Télécharger la nomenclature</v-btn>
        <v-btn text @click="closeDialog" class="button-style">Fermer</v-btn>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  name: "Param",
  data () {
    return {
      dialog: false,
      level: 1,
      levelPopup: 1,
      millesimeList: ["2019", "2010"],
      millesimeSelected: "2019",
      onglet: 0,
      osm: true,
      ortho: false,
      ortho10: false,
      slider: 100
    }
  },
  methods: {
    closeDialog () {
      this.dialog = false
    },
    displayPopupLegend (level) {
      this.dialog = true
      this.levelPopup = level
    },
    // Emet un évènement pour changer la basemap de la carte + surligne l'image correspondant à la basemap sélectionnée
    setBasemap(value) {
      // Si l'utilisateur sélectionne la valeur "ortho"
      if (value === "ortho") {
        this.osm = false
        this.ortho10 = false
        this.ortho = true
      // Sinon, si l'utilisateur sélectionne la value Classic
      } else if (value === "classic") {
        this.osm = true
        this.ortho10 = false
        this.ortho = false
      // Sinon, si l'utilisateur sélectionne la valeur ortho-2010
      } else if (value === "ortho-2010") {
        this.osm = false
        this.ortho10 = true
        this.ortho = false
      }
      // Emet un évènement sur la modification de la basemap
      this.$emit('changeBasemap', value)
    },
    // Emet un évènement changeant les délimitations administratives affichées
    setMapStyle(value) {
      this.$emit("changeMapStyle", value);
    },
  },
  watch: {
    level (newVal) {
      this.$emit('newLevel', newVal)
    },
    // Si le millésime change, envoi un évènement
    millesimeSelected(val) {
      this.$emit("dateChanged", val);
    },
    // Lorsque la valeur de transparence est modifiée, envoi un évènement pour appliquer les modifications
    slider (newVal) {
      this.$emit('updateOpacity', newVal)
    },
  }
}
</script>

<style scoped>
.basemap-selected {
  border: 3px solid black
}
</style>