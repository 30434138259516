<!-- Drawer affichant les options -->
<template>
  <v-container transition="slide-y-reverse-transition" class="compare-options pt-0">
    <Param v-if="displayParam" @newLevel="updateLevel" @updateOpacity="updateOpacity" @dateChanged="updateDate" @changeMapStyle="changeMapStyle" @changeBasemap="updateBasemap"/>
  </v-container>
</template>

<script>
// Importons les différentes options
import Param from "@/components/Compare/Options/Param"

export default {
  name: "CompareOptions",
  components: {
    Param
  },
  props: {
    optionDisplay: {
      type: Number
    }
  },
  computed: {
    displayParam () {
      return this.optionDisplay === 3
    }
  },
  methods: {
    updateDate (newVal) {
      this.$emit('dateChanged', newVal)
    },
    changeMapStyle (newVal) {
      this.$emit('changeMapStyle', newVal)
    },
    updateBasemap (newVal) {
      this.$emit('changeBasemap', newVal)
    },
    updateLevel(newVal) {
      this.$emit('newLevel', newVal)
    },
    updateOpacity(newVal) {
      this.$emit('updateOpacity', newVal)
    }
  }
}
</script>

<style scoped>
.compare-options {
  position: absolute;
  bottom: 0;
  background-color: white;
  height: 40vh;
  box-shadow: 0 -4px 5px 0 rgba(0,0,0,.14);
  z-index: 2;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
</style>